<template>
<div>
  <div class="row mb-lg-5">
    <div class="col">
       <div class="ml-4 mb-3 mr-4">
        <a href="https://maps.google.com?saddr=Current+Location&daddr=46.0856293,13.6337154" target="_blank"><h6 class="mb-1"><span>{{$store.state.booking.property.name}}, {{$store.state.booking.property.address.street}}, {{$store.state.booking.property.address.city}}</span></h6></a>
       </div>
    </div>
  </div>
  <div class="row mb-lg-5">
    <div class="col">
      <div class="d-flex align-items-md-center">
        <!--<img v-bind:src="$store.state.booking.property.coverPhoto" alt="Avatar" class="avatar avatar-lg flex-shrink-0">-->
        <div class="ml-4 mr-4">
          <h3 class="mb-0">
            Room no. {{$store.state.booking.unit.name}}
          </h3>
          <h5>
            <span v-if="$store.state.booking.unit" id="unit">
              {{$store.state.booking.room.name}}
            </span>
            <span v-else>
              Unit is not assigned yet
              <span v-if="!lockDisabled" class="text-danger">
              <br>
                Plase contact your host!
              </span>
            </span>
          </h5>
          <div class="text-small">

            <span class="pr-2 pmr-4" id="booking-date">
              <i class="far fa-calendar-check"></i>
              {{firstNight}} {{checkIn}}
               -
              {{lastNight}} {{checkOut}}
            </span>
            <span>
              <i class="fas fa-users"></i>
              {{$store.state.booking.numGuests.children + $store.state.booking.numGuests.adults}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
const moment = require('moment');
const countryList = require('country-list');

export default {
  name: 'Room',
  computed: {
    firstNight: function(){
      return moment(this.$store.state.booking.night.first).format('DD/MM/YYYY');
    },
    lastNight: function(){
      return moment(this.$store.state.booking.night.last).add(1, 'days').format('DD/MM/YYYY');
    },
    checkIn: function(){
      return moment(this.$store.state.booking.property.time.checkIn, "HH:mm:ss").format('HH:mm');
    },
    checkOut: function(){
      return moment(this.$store.state.booking.property.time.checkOut, "HH:mm:ss").format('HH:mm');
    }
  }
}
</script>
