<template>
  <section class="bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-11">
          <div class="card card-body shadow print">
            <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start pb-4 mb-4 mb-md-5 border-bottom">
              <div class="mb-3 mb-md-0">
                <h1 class="mb-2">Privacy Policy</h1>
                <div class="lead">Updated {{lastUpdated}}</div>
              </div>
              <a href="#" class="btn btn-primary no-print" onclick="window.print()">Print</a>
            </div>
            <article class="article" v-html="privacy.content">
            </article>
            <hr>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const moment = require('moment');

export default {
  name: 'Privacy',
  data: () => ({
    privacy: {  }
  }),
  computed: {
    lastUpdated: function(){
      return moment(this.privacy.updated).format('D/M/YYYY');
    }
  },
  beforeCreate(){
    fetch( process.env.VUE_APP_API + '/policies?_id=privacy', {
      method : 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'CSRF-Token': this.$csrf
      }
    })
    .then(response => response.json())
    .then(response => {
      this.content = response.content;
      if(response.error){
        this.$refs.form.setErrors(response.error.messages);
      }else{
        console.log(response);
        this.privacy = response;
        console.log(this.privacy.content)
      }
    });
  }
}
</script>
