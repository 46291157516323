<template>
  <ValidationObserver v-slot="{ errors }">
    <input type="hidden" v-model="guest._id">

    <div class="form-group">
      <ValidationProvider name="gender" v-bind:vid="'guests.' + index + '.gender'" v-slot="{ errors }" rules="required">
        <div class="d-flex flex-wrap">
          <div class="custom-control custom-radio mr-4">
            <input type="radio" v-bind:id="'male' + _uid" value="M" v-model="guest.gender" class="custom-control-input" :class="{ 'is-invalid': errors[0] }">
            <label class="custom-control-label" v-bind:for="'male' + _uid">Male</label>
          </div>
          <div class="custom-control custom-radio mr-4">
            <input type="radio" v-bind:id="'female' + _uid" value="F" v-model="guest.gender" class="custom-control-input" :class="{ 'is-invalid': errors[0] }">
            <label class="custom-control-label" v-bind:for="'female' + _uid">Female</label>
          </div>
        </div>
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="row form-row">
      <div class="col-sm-6">
        <div class="form-group" v-bind:id="'firstname-' + +index">
          <label>First name</label>
          <ValidationProvider name="first name" v-bind:vid="'guests.' + index + '.name.first'" rules="required" v-slot="{ errors }" id="firstname-">
            <input class="form-control" v-bind:ref="'guests.' + index + '.name.first'" :class="{ 'is-invalid': errors[0] }" v-model="guest.name.first">
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" v-bind:id="'lastname-' + +index">
          <label>Last name</label>
          <ValidationProvider name="last name" v-bind:vid="'guests.' + index + '.name.last'" rules="required" v-slot="{ errors }">
            <input class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.name.last">
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Date of Birth</label>
          <div class="row">
            <div class="col-3 pr-1">
              <ValidationProvider name="day of birth" v-bind:vid="'guests.' + index + '.dob.day'" rules="required" v-slot="{ errors }">
                <select class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.dob.day">
                  <option value="" disabled></option>
                  <option v-for="day in 31" v-bind:value="day">{{day}}</option>
                </select>
              </ValidationProvider>
            </div>
            <div class="col-5 pr-1 pl-1">
              <ValidationProvider name="month of birth" v-bind:vid="'guests.' + index + '.dob.month'" rules="required" v-slot="{ errors }">
                <select class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.dob.month">
                  <option value="" disabled></option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </ValidationProvider>
            </div>
            <div class="col-4 pl-1">
              <ValidationProvider name="year of birth" v-bind:vid="'guests.' + index + '.dob.year'" rules="required" v-slot="{ errors }">
                <select class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.dob.year">
                  <option value="" disabled></option>
                  <option v-for="year in 121" v-bind:value="nextYear - year">{{nextYear - year}}</option>
                </select>
              </ValidationProvider>
            </div>
          </div>
          <span class="text-danger" v-if="errors['guests.' + index + '.dob.day'] || errors['guests.' + index + '.dob.month'] || errors['guests.' + index + '.dob.year']">{{ errors['guests.' + index + '.dob.day'][0] || errors['guests.' + index + '.dob.month'][0] || errors['guests.' + index + '.dob.year'][0] }}</span>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Nationality</label>
          <ValidationProvider name="nationality" v-bind:vid="'guests.' + index + '.country'" rules="required" v-slot="{ errors }">
            <select class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.country">
              <option value="" disabled></option>
              <option v-for="country in countries" :key="country.code" v-bind:value="country.code">{{country.name}}</option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Document number</label>
          <ValidationProvider name="document number" v-bind:vid="'guests.' + index + '.document.number'" rules="required" v-slot="{ errors }">
            <input class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.document.number">
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Document type</label>
          <ValidationProvider name="document type" v-bind:vid="'guests.' + index + '.document.type'" rules="required" v-slot="{ errors }">
            <select class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="guest.document.type">
              <option value="" disabled selected></option>
              <option value="P">Passport</option>
              <option value="I">Identity Card</option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
const moment = require('moment');
const countryList = require('country-list');

export default {
  name: 'Guest',
  props: ['guest', 'index'],
  data: () => ({
    countries: countryList.getData().sort((a, b) => a.name >= b.name ? 1 : -1),
    nextYear: Number(moment().add(1, 'year').format('YYYY'))
  })
}
</script>
