//layout components
import store from './store/store';
import moment from 'moment';

import CheckIn from "./components/CheckIn";
import Error404 from './components/errors/404';

import Terms from './components/legal/Terms';
import Privacy from './components/legal/Privacy';


const routes = [

  {
    path: '/legal/terms-and-conditions',
    name: 'terms',
    component: Terms
  },
  {
    path: '/legal/privacy-policy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/bookings/:bookingId',
    name: 'booking',
    component: () => {
      if(store.state.booking){

        if(!store.state.booking._id){
          return import("./components/Login");
        }

        if(moment(store.state.booking.night.last).add(1, 'days').format("YYYY-MM-DD") + ' ' + store.state.booking.property.time.checkOut >= moment().format('YYYY-MM-DD HH:ii:ss')){
          if(store.state.booking.guests > 0){
            //show booking
            return import("./components/Booking");
          }else{
            //check in
            return import("./components/CheckIn");
          }
        }else{
          //expired
          return import("./components/errors/423");
        }
      }
      //not exists
      return import("./components/errors/404");
    }
  },
  // and finally the default route, when none of the above matches:
  { path: "*", component: Error404 }
];

export default routes;
