import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    booking: null,
    authenticated: false
  },
  mutations: {
    set(state, booking) {
      state.booking = Object.assign({}, state.booking, booking)
    },
    setAuthentication(state, status){
      state.authenticated = status;
    }
  },
  getters: {
    booking: state => state.booking
  },
})
