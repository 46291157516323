<template>
  <section class="p-0 o-hidden">
    <div class="container min-vh-100 d-flex flex-column justify-content-between text-center pt-4 py-md-5">
      <div class="fade-page">
        <img src="../../assets/jumpstart/img/logos/hostomate.svg" width="150px;">
      </div>
      <div class="mt-5">
        <div class="row justify-content-center">
          <div class="col-5 mb-4">
            <img src="../../assets/img/errors/404_Not_Found.jpg" alt="404 Page Not Found" class="img-fluid">
          </div>
          <div class="col-12">
            <h1>404 - Page Not Found</h1>
            <div class="lead">Whoops, it looks like the page you requested wasn't found.</div>
          </div>
        </div>
      </div>
      <div>
        <a href="index.html" class="btn btn-sm btn-outline-light fade-page">Back to Home</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
