<template>
  <section class="bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-11">
          <div class="card card-body shadow print">
            <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start pb-4 mb-4 mb-md-5 border-botto printme">
              <div class="mb-3 mb-md-0">
                <h1 class="mb-2">Terms of Service</h1>
                <div class="lead">Updated {{lastUpdated}}</div>
              </div>
              <button class="btn btn-primary no-print" onclick="window.print()">Print</button>
            </div>
            <article class="article printme" v-html="terms.content">
            </article>
            <hr>
            <!--
            <h6>Fine Print</h6>
            <small>Praesent urna velit, auctor nec turpis et, vehicula lobortis sem. Vivamus convallis mi sagittis eleifend laoreet. Praesent vitae venenatis enim. Nulla tincidunt Varys et lectus rhoncus laoreet. Aenean Gendry est nec diam suscipit iaculis.
              Praesent urna velit, auctor nec turpis et, vehicula lobortis sem. Vivamus convallis mi sagittis eleifend laoreet. Praesent vitae venenatis enim. Nulla tincidunt Varys et lectus rhoncus laoreet.</small>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const moment = require('moment');

export default {
  name: 'Terms',
  data: () => ({
    terms: {  }
  }),
  computed: {
    lastUpdated: function(){
      return moment(this.terms.updated).format('D/M/YYYY');
    }
  },
  beforeCreate(){
    fetch( process.env.VUE_APP_API + '/policies?_id=terms', {
      method : 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'CSRF-Token': this.$csrf
      }
    })
    .then(response => response.json())
    .then(response => {
      if(!response.error) this.terms = response;
    });
  }
}
</script>
