<template>
  <div id="app" v-if="$csrf">
    <router-view :key="$cookies.get('token')"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
  div#app{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
</style>
