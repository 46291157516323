// src/middleware/auth.js

//dependencies
import Vue from 'vue';
import store from '../store/store';

export function bookingMiddleware(to, from, next){
  const bookingId = to.params.bookingId;
  const csrf = Vue.prototype.$csrf;

  let token = $cookies.get('guest');

  if(!bookingId || !csrf) return next();


  let url = '/bookings/' + bookingId;

  fetch(process.env.VUE_APP_API + url, {
    method: 'get',
    credentials: 'include',
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
      "Accept-Charset": "utf-8",
      'CSRF-Token': csrf
    }
  })
  .then(response => {
    if(response.status == 200){
      return response.json()
    }
  })
  .then(result => {
    console.log(result)
    if(!result.error){
      store.commit('set', result);
    }
    return next();
  })
  .catch(() => {
    next();
  });
}
