<template>
  <div>
    <!--<v-tour name="myTour" :steps="steps"></v-tour>-->
    <section class="bg-light pb-5 pt-5">
      <div class="container">
        <div class="row">
          <Room></Room>
        </div>
      </div>
    </section>
    <section class="pb-3 pt-4">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4 d-flex align-items-center">
            <div class="d-inline-block p-3 p-md-4 rounded-circle">
              <img src="../assets/img/kljukica_zelena.svg" alt="Hostomate logo" class="icon">
            </div>
            <div class="ml-4">
              <h1 class="mb-2">
                Check In
              </h1>
              <div class="lead main-description">
                Complete the check-in by filling out the guest information form below.
              </div>
            </div>
          </div>

          <!--<div class="col d-flex align-items-center flex-row-reverse">
            <button type="button" class="btn btn-primary btn-sm rounded-circle" title="Help?" v-on:click="$tours['myTour'].start(1)" id="help">
              <i class="fa fa-question"></i>
            </button>
          </div>-->

          <div class="col-12">
            <div class="alert alert-primary">Your information is always kept encrypted and can never be accessed by third parties.
              <router-link :to="{name: 'privacy'}" target="_blank">View our Privacy Policy</router-link>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="bg-light tab-content pt-5">
      <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <div class="container">



          <div class="row mb-4 mb-md-5 align-items-start">
            <div class="col-lg-8 border-lg-right pr-lg-5">
              <ValidationObserver v-slot="{ handleSubmit, errors }" ref="form">
                <form @submit.prevent="handleSubmit(submit)">

                  <div v-for="(guest, index) in booking.guests" class="mb-3" v-bind:id="'guest-' + index">
                    <div class="row mb-lg-3">
                      <div class="col">
                        <h4 class="d-flex justify-content-between">
                          Guest no. {{index + 1}}
                        </h4>
                      </div>
                    </div>
                    <Guest v-bind.sync="{ guest: guest, index: index }"></Guest>
                    <hr>
                  </div>

                  <div class="form-group pb-3">
                    <label>Email address for receiving the invoice:</label>
                    <ValidationProvider name="email" :vid="booking.payer.email" rules="required|email" v-slot="{ errors }">
                      <input class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="booking.payer.email">
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    
                  </div>

                  <div class="form-group pb-3">
                    <ValidationProvider name="terms &amp; conditions" vid="policies.terms" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                      <div class="custom-control custom-checkbox text-small">
                        <input v-model="booking.policies.terms" type="checkbox" class="custom-control-input" :class="{ 'is-invalid': errors[0] }" id="sign-up-agree" v-bind:true-value="policies.terms.version">
                        <label class="custom-control-label" for="sign-up-agree">
                          I agree to the
                          <router-link :to="{name: 'terms'}" target="_blank">Terms &amp; Conditions</router-link>
                        </label>
                      </div>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="text-right">
                    <button class="btn btn-primary" type="submit" :disabled="isSubmitting">Submit</button>
                  </div>
                </form>
              </ValidationObserver>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Room from './partials/Room';
import Guest from './partials/Guest';

const moment = require('moment');
const countryList = require('country-list');

export default {
  name: 'CheckIn',
  components: {
    Room, Guest
  },
  data: () => ({
    steps: [
      {
        target: '#help',  // We're using document.querySelector() under the hood
        header: {
          title: 'Need help?',
        },
        content: `Discover <strong>Hostomate Tour</strong>!`,
        params: {
          placement: 'bottom'
        }
      }
    ],
    isSubmitting: false,
    policies: {
      'terms': {},
    },
    'booking': {
      'policies': {
        'terms': ''
      },
      'guests': [],
      'payer': {
        'email':''
      }
    }
  }),
  beforeCreate(){
    fetch( process.env.VUE_APP_API + '/policies?_id=terms', {
      method : 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'CSRF-Token': this.$csrf
      }
    })
    .then(response => response.json())
    .then(response => {
      if(!response.error) this.policies.terms = response;
    });
  },
  created(){
    for(let i = 0; i < this.$store.state.booking.numGuests.adults + this.$store.state.booking.numGuests.children; i++){
      this.booking.guests.push({
        'name': {
          'first': '',
          'last': ''
        },
        document: {
          'type': '',
          'number': ''
        },
        'country': '',
        'gender': '',
        'dob': {
          'day': '',
          'month': '',
          'year': ''
        }
      });

      let ordinal = 'th';
      if(i == 0){
        ordinal = 'st';
      }else if(i == 1){
        ordinal = 'nd';
      }else if(i ==  2){
        ordinal = 'rd';
      }


      this.steps.push({
        target: '#guest-' + i,
        content: "Enter " + (i+1) + ordinal + " guest information!",
        params: {
          placement: 'top'
        }
      });
    }

    this.steps.push({
      target: '#sign-up-agree',
      content: "Read and agree with terms and conditions!",
      params: {
        placement: 'bottom'
      }
    });

    this.steps.push({
      target: 'button[type="submit"]',
      content: "Submit the information!"
    });
  },
  mounted(){
    /*this.$tours['myTour'].start();*/
  },
  methods: {
    submit: function(e){
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      return fetch( process.env.VUE_APP_API + '/guests?bookingId=' + this.$route.params.bookingId, {
        method : 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'CSRF-Token': this.$csrf
        },
        body: JSON.stringify(this.booking)
      })
      .then(response => response.json())
      .then(response => {
        this.isSubmitting = false;
        if(response.error){
          console.log(response.error);
          this.$refs.form.setErrors(response.error.messages);
        }else{
          this.$router.go(0);
        }
      });
    },
  }
}
</script>
