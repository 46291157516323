//If you want to support older IE, Android, and iOS device web browsers,
/*import 'core-js'
import 'regenerator-runtime/runtime'
import 'intersection-observer' // Optional*/

//core dependencies
import Vue from 'vue'

import VueRouter from 'vue-router';
import App from './App.vue';
import routes from '../routes';

//cookies
import VueCookies from 'vue-cookies';

import {bookingMiddleware} from '../middleware/booking';
import store from '../store/store';

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VueTour from 'vue-tour'
Vue.use(VueTour)

import 'vue-tour/dist/vue-tour.css';

import '../assets/css/fontawesome-all.css';
import '../assets/jumpstart/css/main.css';

//validation dependencies
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('en', en);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueCookies);

const router = new VueRouter({
  mode: 'history', //no hash in url, like normal routing
  routes: routes
});


fetch(process.env.VUE_APP_API + '/csrf/token', {
  method: 'get',
  credentials: 'include'
})
.then(response => response.json())
.then(response => {
  return Vue.prototype.$csrf = response.token
})
.then(() => {

  router.beforeEach(bookingMiddleware);

  //router.afterEach(cssLoader)

  new Vue({
    store,
    render: h => h(App),
    router,
  }).$mount('#app')
})
